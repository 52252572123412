/* HealthOptimizationsTable.css */

/* General table styling */
.table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 8px;
  overflow: hidden;
  background-color: #f8f9fa;
}

.table thead {
  background-color: #e3f2fd; /* Light blue header background */
}

.table thead th {
  text-align: left;
  padding: 12px;
  font-weight: bold;
  color: #212529;
}

.table tbody tr {
  border-bottom: 1px solid #dee2e6;
}

.table tbody tr:last-child {
  border-bottom: none;
}

.table tbody td {
  padding: 12px;
  font-size: 14px;
  color: #495057;
}

/* Responsive design */
.table-responsive {
  overflow-x: auto;
}

/* Status pill styling */
.status-pill {
  display: inline-block;
  padding: 4px 12px;
  border-radius: 20px;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
}

/* Status-specific colors */
.status-completed {
  background-color: #e3f2fd; /* Light blue */
  color: #0d6efd; /* Blue */
}

.status-expiring {
  background-color: #e9f7ef; /* Light green */
  color: #28a745; /* Green */
}

.status-pending {
  background-color: #f8f9fa; /* Light gray */
  color: #6c757d; /* Gray */
}

.status-in-progress {
  background-color: #fff3cd; /* Light yellow */
  color: #ffc107; /* Yellow */
}

/* Table border styling */
.table.border {
  border: 1px solid #dee2e6;
  border-radius: 8px;
  overflow: hidden;
}
