body {
  margin: 0;
  font-family: 'Epilogue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.blue-icon {
  color: #1744A0; /* Bootstrap's primary blue color */
  font-size: 16px; /* Optional size adjustment */
}

.no-underline {
  text-decoration: none;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
