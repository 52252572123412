.slide-enter {
    opacity: 0;
    transform: translateX(100%);
  }
  
  .slide-enter-active {
    opacity: 1;
    transform: translateX(0%);
    transition: all 500ms ease-in-out;
  }
  
  .slide-exit {
    opacity: 1;
    transform: translateX(0%);
  }
  
  .slide-exit-active {
    opacity: 0;
    transform: translateX(-100%);
    transition: all 500ms ease-in-out;
  }
  