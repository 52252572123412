/* General Styling */
body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
}

.login-container {
  display: flex;
  height: 100vh;
}

.login-left {
  flex: 1;
  position: relative;
}

.background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.login-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 20px;
}

.brand-name {
  font-size: 2rem;
  color: #007bff;
  font-weight: bold;
  text-align: center;
}

.welcome-text {
  font-size: 1.5rem;
  margin: 20px 0;
  text-align: center;
}

.login-buttons {
  width: 100%;
  max-width: 300px;
}

.login-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px 15px;
  margin: 10px 0;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.login-button img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.google {
  background-color: #fff;
  border: 1px solid #ddd;
}

.facebook {
  background-color: #3b5998;
  color: white;
}

.email {
  background-color: #f4f4f4;
  border: 1px solid #ddd;
}

.signup-link {
  margin-top: 20px;
  font-size: 0.9rem;
  text-align: center;
}

.signup-link a {
  color: #007bff;
  text-decoration: none;
}

.signup-link a:hover {
  text-decoration: underline;
}

/* Responsive Adjustments for Mobile */
@media (max-width: 768px) {
  .login-container {
    flex-direction: column;
    height: auto;
  }

  .login-left {
    display: none; /* Hide the left image on smaller screens */
  }

  .login-right {
    padding: 10px;
    width: 100%;
  }

  .brand-name {
    font-size: 1.8rem;
  }

  .welcome-text {
    font-size: 1.2rem;
  }

  .login-buttons {
    max-width: 100%;
  }

  .login-button {
    padding: 12px;
    font-size: 1rem;
  }

  .signup-link {
    font-size: 0.85rem;
  }
}

@media (max-width: 480px) {
  .brand-name {
    font-size: 1.5rem;
  }

  .welcome-text {
    font-size: 1rem;
  }

  .login-button {
    padding: 10px;
    font-size: 0.9rem;
  }

  .signup-link {
    font-size: 0.8rem;
  }
}
