/* Fade in the background image */
.background-image {
  opacity: 1;
  transition: opacity 500ms ease-in-out;
}

.background-image.loaded {
  opacity: 1;
}

.background-image.exiting {
  opacity: 0;
}

/* Slide and fade transition for content */
.slide-fade-enter {
  opacity: 0;
  transform: translateY(20px);
}

.slide-fade-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 500ms ease-in-out, transform 500ms ease-in-out;
}

.slide-fade-exit {
  opacity: 1;
  transform: translateY(0);
}

.slide-fade-exit-active {
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 500ms ease-in-out, transform 500ms ease-in-out;
}
